<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" :close-on-click-modal="false"
                destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <x-divider title="基本信息"></x-divider>
            <el-form-item label="报损人" prop="baoSunRen" >
                {{form.baoSunRen}}
            </el-form-item>
            <el-form-item label="备注" prop="beiZhu" >
                <el-input v-model="form.beiZhu" size="small" maxlength="255" show-word-limit/>
            </el-form-item>
            <el-form-item label="主要事项" prop="title" >
                <el-input v-model="form.title" size="small" maxlength="100" show-word-limit/>
            </el-form-item>
            <x-divider title="审核信息"></x-divider>
            <el-form-item label="审核状态">
                <x-dict-show :code="form.auditStatus" dictType="T_AUDIT_STATUS"/>
            </el-form-item>
            <el-form-item label="审核日期">{{form.auditDate}}</el-form-item>
            <el-form-item label="审核人">{{form.auditorName}}</el-form-item>
            <el-form-item label="审核意见" class="line">{{form.auditMind}}</el-form-item>
            <x-divider title="报损信息详情"></x-divider>
            <x-selector
                    placement="center"
                    selector="KuCunZhuangKuangSelector"
                    @selected="handleClSelect"
                    code-key="id"
                    :param="{'cangKuMC': ''}"
                    selection
                    width="1000px">
                <el-button size="mini" type="success" round>添加商品</el-button>
            </x-selector>
            <x-row :rows="form.baoSunXinXiXiangQings" :rowModel="baoSunXinXiXiangQingsModel"
                   no-add-btn>
<!--                <x-row-cell title="报损仓库ID" read="baoSunCangKuID" width="180"/>-->
                <x-row-cell title="报损仓库名称" read="baoSunCangKuMC" width="180"/>
<!--                <x-row-cell title="报损商品ID" read="baoSunShangPinID" width="180"/>-->
                <x-row-cell title="报损商品名称" read="baoSunShangPinMC" width="180"/>
<!--                <x-row-cell title="规格" read="guiGe" width="180"/>-->
<!--                <x-row-cell title="条码" read="tiaoMa" width="180"/>-->
                <x-row-cell title="货号" read="huoHao" width="180"/>
                <x-row-cell title="单位" read="danWei" width="180"/>
                <x-row-cell title="成本价" read="chengBenJia" width="180"/>
                <x-row-cell title="生产日期" read="shengChanRQ" width="180"/>
                <x-row-cell title="保质期（天）" read="baoZhiQiTian" width="180"/>
                <x-row-cell title="当前库存数量" read="dangQianKuCunSL" width="180"/>
                <x-row-cell title="报损数量" width="120" prop="baoSunSL">
                    <el-input slot-scope="{row}" v-model="row.baoSunSL" size="small" maxlength="15" show-word-limit/>
                </x-row-cell>
                <x-row-cell title="备注" width="120" prop="beiZhu">
                    <el-input slot-scope="{row}" v-model="row.beiZhu" size="small" maxlength="20" show-word-limit/>
                </x-row-cell>
            </x-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
         <!--   <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>-->
            <el-button type="primary" @click="commit" icon="el-icon-document-add">提 交</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/kcgl/BaoSunXX";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import XUserSelector from "@/components/x/selector/XUserSelector";
    import {getListByCangKuMC}from "@/service/kcgl/KuCunZhuangKuang";
    import KuCunZhuangKuangSelector from "@/view/selector/KuCunZhuangKuangSelector";

    export default {
        mixins: [XEditBase],
        components: {XUserSelector,KuCunZhuangKuangSelector},
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                baoSunDanID: [], // 报损单ID
                baoSunSJ: [], // 报损时间
                baoSunShangPinZhongShu: [], // 报损商品种数
                baoSunZongJinE: [], // 报损总金额
                baoSunRen: [], // 报损人
                baoSunRenID: [], // 报损人ID
                beiZhu: [], // 备注
                title: [], // 主要事项
            }
            this.titlePrefix = '报损信息';
            this.baoSunXinXiXiangQingsModel = {
                id: "",//
                kuCunZhuangKuangId: "",//库存状况id
                baoSunCangKuID: "", // 报损仓库ID
                baoSunCangKuMC: "", // 报损仓库名称
                baoSunShangPinID: "", // 报损商品ID
                baoSunShangPinMC: "", // 报损商品名称
                guiGe: "", // 规格
                tiaoMa: "", // 条码
                huoHao: "", // 货号
                danWei: "", // 单位
                chengBenJia: "", // 成本价
                shengChanRQ: "", //生产日期
                baoZhiQiTian: "", //保质期（天）
                dangQianKuCunSL: "", // 当前库存数量
                baoSunSL: "", // 报损数量
                baoSunJinE: "", // 报损金额
                beiZhu: "", // 备注
            }
            this.defaultForm = {
                id: null,
                baoSunDanID: "", // 报损单ID
                baoSunSJ: "", // 报损时间
                baoSunShangPinZhongShu: "", // 报损商品种数
                baoSunZongJinE: "", // 报损总金额
                baoSunRen: this.$store.getters.user.name, // 报损人
                baoSunRenID: this.$store.getters.user.id, // 报损人ID
                beiZhu: "", // 备注
                title: "", // 主要事项
                auditStatus: "",
                auditDate: "",
                auditorName: "",
                auditMind: "",
                baoSunXinXiXiangQings: [],
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
        methods: {
            handleClSelect(cls) {
                let cc = [];
                this.form.baoSunXinXiXiangQings.forEach(item=>cc.push(item.kuCunZhuangKuangId));
                cls.forEach(cl => {
                    let obj = {};
                    if(!cc.includes(cl.id)){
                        obj.kuCunZhuangKuangId = cl.id;
                        obj.baoSunCangKuID = cl.cangKuBH || '';
                        obj.baoSunCangKuMC = cl.cangKuMC || '';
                        obj.baoSunShangPinID = cl.shangPinId || '';
                        obj.huoHao = cl.shangPinHuoHao || '';
                        obj.tiaoMa = cl.shangPinTiaoMa || '';
                        obj.guiGe = cl.shangPinGuiGe || '';
                        obj.danWei = cl.shangPinDW || '';
                        obj.baoSunShangPinMC = cl.shangPinMC || '';
                        obj.chengBenJia = cl.chengBenJia || '';
                        obj.shengChanRQ = cl.shengChanRQ || '';
                        obj.baoZhiQiTian = cl.baoZhiQiTian || '';
                        obj.dangQianKuCunSL = cl.shiJiKuCun || '';
                        this.form.baoSunXinXiXiangQings.push(obj);
                    }
                });
            },
            //获取商品信息列表
            change(cangKuMC){
                getListByCangKuMC(cangKuMC).then((response) => {
                    this.spxxs = response.data
                })
            }
        }
    }
</script>

<style scoped>

</style>