<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="报损单ID" >
                <el-input v-model="search.baoSunDanID" size="small" />
            </x-search-item>
            <x-search-item label="报损时间" textWidth="238px" hasEnd>
                <el-date-picker v-model="search.baoSunSJBegin" size="small" type="datetime" />
                <el-date-picker v-model="search.baoSunSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <x-search-item label="报损人" >
                <el-input v-model="search.baoSunRen" size="small" />
            </x-search-item>
            <x-search-item label="主要事项" >
                <el-input v-model="search.title" size="small" />
            </x-search-item>
            <x-search-item label="审核状态" >
                <x-selector-one v-model="search.auditStatus" size="small" dictType="T_AUDIT_STATUS"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 批量按钮区 -->
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
<!--            <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDeleteBatch">-->
<!--                <el-button type="danger" size="mini" round slot="reference">批量删除</el-button>-->
<!--            </el-popconfirm>-->
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40" fixed/>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="baoSunDanID" label="报损单ID" />
            <el-table-column width="180" prop="baoSunSJ" label="报损时间" />
            <el-table-column width="180" prop="baoSunShangPinZhongShu" label="报损商品种数" />
            <el-table-column width="180" prop="baoSunZongJinE" label="报损总金额" />
            <el-table-column width="180" prop="baoSunRen" label="报损人" />
            <el-table-column width="180" prop="baoSunRenID" label="报损人ID" />
            <el-table-column width="180" prop="beiZhu" label="备注" />
            <el-table-column width="180" prop="title" label="主要事项" />
            <el-table-column width="180" label="审核状态">
                <x-dict-show slot-scope="{row}" :code="row.auditStatus" dictType="T_AUDIT_STATUS" />
            </el-table-column>
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
                    <el-button type="primary" size="mini" round v-if="row.auditStatus == 0 || row.auditStatus == 99" @click="handleUpdate(row.id)">编辑</el-button>
                    <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDelete(row.id)">
                        <el-button type="danger" size="mini" round v-if="row.auditStatus == 0 || row.auditStatus == 99" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/kcgl/BaoSunXX";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/kcgl/bsgl/BaoSunXXEdit";
    import Detail from "@/view/kcgl/bsgl/BaoSunXXDetail";

    export default {
        name: "BaoSunXXList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    baoSunDanID: '',
                    baoSunSJBegin: '',
                    baoSunSJEnd: '',
                    baoSunRen: '',
                    title: '',
                    auditStatus: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>
